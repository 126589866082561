
import one from './../assets/services/مكافحة خشرات.jpg'
import one1 from './../assets/services/حشرات1.jpg'
import one2 from './../assets/services/حشرات2.jpg'
import tow from './../assets/services/منازل.png'
import tow1 from './../assets/services/منازل1.jpg'
import tow2 from './../assets/services/منازل2.jpg'
import three from './../assets/services/مجلس.jpg'
import three1 from './../assets/services/مجالس1.jpg'
import three2 from './../assets/services/مجالس2.jpg'
import four from './../assets/services/شقق.jpg'
import four1 from './../assets/services/شقق1.jpg'
import four2 from './../assets/services/شقق2.jpg'
import six from './../assets/services/سجاد.png'
import six1 from './../assets/services/سجاد1.jpg'
import six2 from './../assets/services/سجاد2.jpg'
import six3 from './../assets/services/ستائر1.jpg'
import six4 from './../assets/services/ستائر2.jpg'
import siven from './../assets/services/قصور.png'
import siven1 from './../assets/services/قصور1.jpg'
import siven2 from './../assets/services/قصور2.jpg'
import heaht from './../assets/services/تنظيف خزانات.png'
import heaht1 from './../assets/services/خزانات1.jpg'
import heaht2 from './../assets/services/خزانات2.jpg'
import nighn from './../assets/services/Placeholder.png'
import nighn1 from './../assets/services/فلل1.jpg'
import nighn2 from './../assets/services/فلل2.jpg'
import tin from './../assets/services/مساجد.png'
import tin1 from './../assets/services/مساجد1.jpg'
import tin2 from './../assets/services/مساجد2.jpg'
import elevin from './../assets/services/فنادق.png'
import elevin1 from './../assets/services/فنادق1.jpg'
import elevin2 from './../assets/services/فنادق2.jpg'
import elevin3 from './../assets/services/فنادق3.jpg'
import tuelv from './../assets/services/مسابح.png'
import tuelv1 from './../assets/services/مسابح1.jpg'
import tuelv2 from './../assets/services/مسابح2.jpg'

export const products = [
        {
                id: 1,
                imageUrl: [
                        one,
                        one1,
                        one2,
                ],
                name: `مكافحة حشرات بالرياض`,
                supName: `مكافحة حشرات `,
                desc: `الحشرات من أكثر الأشياء المزعجة بشكل غير معتاد و غير مرغوب فى تواجها من الأساس وذلك لأنها سبب إنتشار الأمراض و نقل العدوى بين أفراد الأسرة ناهيك عن الشعور السئ التى تتركة عند الجميع ، بالطبع لا أحد يرغب في ذلك يحدث ،لهذا يجب الإهتمام برش المبيدات بشكل منتظم و مستمر فى مواعيد محددة لقتل الحشرات و منعها من المنزل بشكل نهائي .`,
                supDesc: `نحن نستخدم أفضل المبيدات الحشرية الأمنة عند مكافحة بق الفراش والتخلص من كافة الحشرات و نقوم بالمتابعة الدورية كل 3شهور لضمان عدم عوداتها مرة أخرى ، لا تقلق لأننا نستخدم مواد أمنة على صحة الأطفال وكبار السن ودع الحشرات بشكل فعال معنا .`,
        },
        {
                id: 2,
                imageUrl: [
                        tow,
                        tow1,
                        tow2,
                ],
                name: `شركة تنظيف منازل بالرياض`,
                supName: ` شركة تنظيف منازل `,
                desc: `متخصصون في تنظيف المنازل بطاقم عمل يقوم بتنفيذ كافة أعمال التنظيف بطريقة مميزة ومختلفة لأنه مدرب على أعلى مستوى ،نستخدم أحدث الأجهزة والوسائل التقنية للتنظيف وأجود و أقوى المساحيق و أفخم المعطرات و مواد تطهير عالمية للقضاء على كافة المكروبات.`,
                supDesc: `  بأسعار تنافسية ومناسبة للجميع من أفراد و شركات أجود وأعلى خدمة تعتبر مميزة بين شركات التنظيف المختلفة.`,
        },
        {
                id: 3,
                imageUrl: [
                        three,
                        three1,
                        three2,
                ],
                name: `شركة تنظيف مجالس بالرياض`,
                supName: `شركة تنظيف مجالس `,
                desc: `تنظيف المجالس يعتبر أمر شاق يحتاج إلى أدوات مخصصة و مواد تنظيف قوية لذلك ضروري تعتمد على شركة مميزه فى عمليات التنظيف .`,
                supDesc: `تتيح العديد من الخدمات مثل تنظيف المفروشات و الستائر والمجالس بطرق حديثة ومتطورة بالإضافة للحفاظ على المفروشات من التلف نمتلك أفضل الأجهزة و المعدات الخاصة بالتنظيف نقدم أقل الأسعار نظافة تجلب لك الراحة و الإستماع بالمكان.`,
        },
        {
                id: 4,
                imageUrl: [
                        four,
                        four1,
                        four2,
                ],
                name: `شركة تنظيف شقق بالرياض`,
                supName: `شركة تنظيف شقق `,
                desc: `شركة الأفضل تعد واحده من شركات التنظيف الرائده في الرياض لأنها تقدم خدمات مميزة و تمتلك أحدث طرق التنظيف و لديها طاقم عمل مدرب تقوم على تحسين الخدمات و تحفيزهم بشكل دائم للقيام بالتنظيف على أكمل وجه.`,
                supDesc: `كما نوفر أيضاً فريق عمل أجنبى لخدمتكم من الفلبينية و الهندية و اليبانية مدرب بشكل ٱحترافي`,
        },
        {
                id: 6,
                imageUrl: [
                        six,
                        six1,
                        six2,
                        six3,
                        six4,
                ],
                name: `تنظيف السجاد و الستائر`,
                supName: `تنظيف السجاد والستائر`,
                desc: `السجاد والستائر من الأقمشة التى تحتاج تعامل بشكل خاص نقوم بتنظفها عن طريق البخار للحصول على أفضل النتائج `,
                supDesc: `و نستخدم أجود مواد النظافة المستوردة والمصرح بها لإزالة البقع و الأتربة و التعقيم بشكل محترف للستائر والسجاد و الحفاظ عليهم بشكل كامل دون أى تلف للأنسجة.`,
        },
        {
                id: 7,
                imageUrl: [
                        siven,
                        siven1,
                        siven2,
                ],
                name: `تنظيف القصور  بالرياض`,
                supName: `شركة تنظيف قصور `,
                desc: `تنظيف القصور يحتاج إلى خبرة و معدات حديثة لإزالة الأتربة والبقع للحفاظ على مظهر و جمال القصر خدماتنا متاحة على مدار 24 ساعة لتلبية إحتياجات العملاء في أى وقت و بأقصى سرعة ممكنة لتكوين علاقة قوية بين العميل و الشركة ، متاح شاحنة مجهزة بها جميع معدات الشركة .`,
                supDesc: `تعتمد الشركة على عمالة مدربة و مؤهلة للقيام بجميع خدمات التنظيف بصورة ممتازة بإستخدام مواد قوية المفعول لإزالة أصعب البقع و الحصول على أعلى نظافة.`,
        },
        {
                id: 8,
                imageUrl: [
                        heaht,
                        heaht1,
                        heaht2,
                ],
                name: `تنظيف وعزل خزانات المياه`,
                supName: ` تنظيف وعزل خزانات المياه`,
                desc: `أغلب مصادر المياة المستخدمة تأتى من الخزانات بأنواعها المختلفة وإن الإهمال في نظافتها ينتج عنه أضرار بالغة الخطورة وتؤثر على صحة الإنسان و الأطفال وتؤدي أحيانا إلى الوفاة .`,
                supDesc: `شركة الأفضل تهتم بنظافة الخزانات مع العزل و التعقيم بمواد مصرح بها من خلال طاقم ذو خبرة في كيفية التعامل مع كافة أنواع الخزانات و أدوات التنظيف اللازمة للحصولك على أحسن خدمة .`,
        },
        {
                id: 9,
                imageUrl: [
                        nighn,
                        nighn1,
                        nighn2,
                ],
                name: `تنظيف الفلل  بالرياض`,
                supName: `شركة تنظيف فلل `,
                desc: `نعد أفضل شركة تنظيف فلل بالرياض نتميز بالدقة و الجودة و العناية بأدق التفاصيل من خلال أحدث الأدوات و المعدات التكنولوجيا الحديثة نقدم أعلى أداء دون حدوث أى تلفيات فى مستلزمات الفلل و إزالة أصعب البقع و الوصول لأدق الأماكن.`,
                supDesc: `تعقيم الفلل بشكل إحترافي نقدم الخدمة بالمملكة العربية السعودية فى كافة الأنحاء وليس بالرياض فقط.`,
        },
        {
                id: 10,
                imageUrl: [
                        tin,
                        tin1,
                        tin2,
                ],
                name: `تنظيف مساجد  بالرياض`,
                supName: `شركة تنظيف مساجد `,
                desc: `نظراً لأهمية المساجد و المساحات الكبيرة الشركة تقدم أحدث الأساليب للقيام بتنظفها بشكل دقيق فى كل الأماكن و بشكل سريع .`,
                supDesc: `نستخدم أجهزة البخار في التنظيف بشكل دقيق وعميق للمفروشات و الموكيت و مسح الأرضيات بأحدث المعدات ،االتنظيف يكون بين إقامة العشاء و أذان الفجر نقدم أسعار مخفضة خاصة لعملائنا الكرام.`,
        },
        {
                id: 11,
                imageUrl: [
                        elevin,
                        elevin1,
                        elevin2,
                        elevin3,
                ],
                name: `شركة تنظيف فنادق  بالرياض`,
                supName: `شركة تنظيف فنادق `,
                desc: `تعتبر الفنادق من الأماكن المزدحمة و ذات إقبال دايم  هذا يجعلها في حاجة مستمرة إلى التنظيف لذلك نقوم بعملية التنظيف بأحدث المعدات والأجهزة.`,
                supDesc: `نستخدم أجود المواد المستوردة والمعطرات الفاخرة لنظافة دائمه و تعامل بأرقى الأساليب مع العملاء .`,
        },
        {
                id: 12,
                imageUrl: [
                        tuelv,
                        tuelv1,
                        tuelv2,
                ],
                name: `شركة تنظيف مسابح  بالرياض`,
                supName: `شركة تنظيف مسابح  `,
                desc: `نظرا لأن المسابح وسيلة ترفيهية دائمه فى كافة الفصول و محبة لكل الأعمار بذلك بحاجة للنظافة المستمرة و التعقيم الدايم.`,
                supDesc: `لذلك نقوم بعملية تنظيفة بشكل دورى و مسمتر بأحدث المعدات وأجود أنواع المواد وأحدث طرق التعقيم باستخدام مواد عالية الجودة لتخلص من البكتيريا المسببة للأمراض طاقم العمل قادر على التعامل مع كل أنواع المسابح المختلفة بكفاءة عالية.`,
        }
];
export const topProducts = [
        {
                id: 1,
                imageUrl: one,
                name: `مكافحة حشرات بالرياض`,
                supName: `مكافحة حشرات `,
                desc: `الحشرات من أكثر الأشياء المزعجة بشكل غير معتاد و غير مرغوب فى تواجها من الأساس وذلك لأنها سبب إنتشار الأمراض و نقل العدوى بين أفراد الأسرة ناهيك عن الشعور السئ التى تتركة عند الجميع ، بالطبع لا أحد يرغب في ذلك يحدث ،لهذا يجب الإهتمام برش المبيدات بشكل منتظم و مستمر فى مواعيد محددة لقتل الحشرات و منعها من المنزل بشكل نهائي .`,
                supDesc: `نحن نستخدم أفضل المبيدات الحشرية الأمنة عند مكافحة بق الفراش والتخلص من كافة الحشرات و نقوم بالمتابعة الدورية كل 3شهور لضمان عدم عوداتها مرة أخرى ، لا تقلق لأننا نستخدم مواد أمنة على صحة الأطفال وكبار السن ودع الحشرات بشكل فعال معنا .`,
        },
        {
                id: 2,
                imageUrl: tow,
                name: `شركة تنظيف منازل بالرياض`,
                supName: ` شركة تنظيف منازل `,
                desc: `متخصصون في تنظيف المنازل بطاقم عمل يقوم بتنفيذ كافة أعمال التنظيف بطريقة مميزة ومختلفة لأنه مدرب على أعلى مستوى ،نستخدم أحدث الأجهزة والوسائل التقنية للتنظيف وأجود و أقوى المساحيق و أفخم المعطرات و مواد تطهير عالمية للقضاء على كافة المكروبات.`,
                supDesc: `  بأسعار تنافسية ومناسبة للجميع من أفراد و شركات أجود وأعلى خدمة تعتبر مميزة بين شركات التنظيف المختلفة.`,
        },
        {
                id: 3,
                imageUrl: three,
                name: `شركة تنظيف مجالس بالرياض`,
                supName: `شركة تنظيف مجالس `,
                desc: `تنظيف المجالس يعتبر أمر شاق يحتاج إلى أدوات مخصصة و مواد تنظيف قوية لذلك ضروري تعتمد على شركة مميزه فى عمليات التنظيف .`,
                supDesc: `تتيح العديد من الخدمات مثل تنظيف المفروشات و الستائر والمجالس بطرق حديثة ومتطورة بالإضافة للحفاظ على المفروشات من التلف نمتلك أفضل الأجهزة و المعدات الخاصة بالتنظيف نقدم أقل الأسعار نظافة تجلب لك الراحة و الإستماع بالمكان.`,
        },
        {
                id: 4,
                imageUrl: four,
                name: `شركة تنظيف شقق بالرياض`,
                supName: `شركة تنظيف شقق `,
                desc: `شركة الأفضل تعد واحده من شركات التنظيف الرائده في الرياض لأنها تقدم خدمات مميزة و تمتلك أحدث طرق التنظيف و لديها طاقم عمل مدرب تقوم على تحسين الخدمات و تحفيزهم بشكل دائم للقيام بالتنظيف على أكمل وجه.`,
                supDesc: `كما نوفر أيضاً فريق عمل أجنبى لخدمتكم من الفلبينية و الهندية و اليبانية مدرب بشكل ٱحترافي`,
        },
        {
                id: 6,
                imageUrl: six,
                name: `تنظيف السجاد و الستائر`,
                supName: `تنظيف السجاد والستائر`,
                desc: `السجاد والستائر من الأقمشة التى تحتاج تعامل بشكل خاص نقوم بتنظفها عن طريق البخار للحصول على أفضل النتائج `,
                supDesc: `و نستخدم أجود مواد النظافة المستوردة والمصرح بها لإزالة البقع و الأتربة و التعقيم بشكل محترف للستائر والسجاد و الحفاظ عليهم بشكل كامل دون أى تلف للأنسجة.`,
        },
        {
                id: 7,
                imageUrl: siven,
                name: `تنظيف القصور  بالرياض`,
                supName: `شركة تنظيف قصور `,
                desc: `تنظيف القصور يحتاج إلى خبرة و معدات حديثة لإزالة الأتربة والبقع للحفاظ على مظهر و جمال القصر خدماتنا متاحة على مدار 24 ساعة لتلبية إحتياجات العملاء في أى وقت و بأقصى سرعة ممكنة لتكوين علاقة قوية بين العميل و الشركة ، متاح شاحنة مجهزة بها جميع معدات الشركة .`,
                supDesc: `تعتمد الشركة على عمالة مدربة و مؤهلة للقيام بجميع خدمات التنظيف بصورة ممتازة بإستخدام مواد قوية المفعول لإزالة أصعب البقع و الحصول على أعلى نظافة.`,
        },
];
