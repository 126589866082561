import { Box, Button, InputLabel, Typography } from '@mui/material'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import './form.css'
const Form = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service', 'template', form.current, 'user')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <div>
            <Box sx={{ borderRadius: '30px', }}>
                <Typography sx={{ fontWeight: 'bold', color: '#3B70A3', my: 1 }} variant='h4' >ارسل رساله</Typography>
                <Typography sx={{ fontWeight: 'bold' }} variant='h5' >متواجدون على مدار الساعة لخدمتكم</Typography>
                <Box component={'form'} ref={form} onSubmit={sendEmail} >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} >
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}>
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                الأسم <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <input
                                fullWidth
                                style={{ backgroundColor: '#FFF' }}
                                hiddenLabel
                                required
                                // placeholder='Name'
                                id="name"
                                variant="filled"
                                size="medium"
                                name='name'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                رقم التلفون <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <input
                                required
                                fullWidth
                                style={{ backgroundColor: '#FFF' }}
                                hiddenLabel
                                // placeholder='E-mail'
                                id="email"
                                variant="filled"
                                size="medium"
                                name='email'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: '100%', my: 2 }}  >
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                الأيميل <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <input
                                required
                                fullWidth
                                style={{ backgroundColor: '#FFF' }}
                                hiddenLabel
                                // placeholder='Phone'
                                id="Phone"
                                variant="filled"
                                size="medium"
                                name='phone'
                                className='input'
                            />
                        </Box>
                        <Box sx={{ width: { md: '100%', xs: '100%' }, }}  >
                            <InputLabel htmlFor="bootstrap-input" sx={{ my: 1 }} >
                                رسالتك <span style={{ color: '#FFA53B' }} >*</span>
                            </InputLabel>
                            <textarea
                                required
                                fullWidth
                                style={{ backgroundColor: '#FFF', height: '200px', padding: '20px' }}
                                hiddenLabel
                                id="outlined-multiline-flexible"
                                multiline
                                rows={6}

                                // placeholder='Your Message'
                                // id="Message"
                                variant="filled"
                                size="medium"
                                name='message'
                                className='input'
                            />
                        </Box>
                    </Box>
                    <Box mt={2} >
                        <Button variant="contained"
                            type="submit"
                            // fullWidth}
                            sx={{
                                backgroundColor: '#239698', ':hover': {
                                    backgroundColor: '#239698'
                                },
                                color: "#FFF",
                                fontWeight: 'bold',
                                width: '100px'
                                // margin: 3
                            }}>ارسال</Button>
                    </Box>
                </Box>
            </Box>
        </div >
    )
}
export default Form
