import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import Accordions from '../Accordions'
import './Sfaq.css'
const Sfaq = () => {
    return (
        <div className="bg">
            <div className="overlayD"></div>
            <Container>
                <Box my={2} textAlign={'center'} sx={{ position: 'relative', zIndex: 9 }} >
                    <Typography sx={{ color: '#fff', my: 2, }} variant='h4' >بعض الأسئلة المتكررة</Typography>
                </Box>
                <Box display={"flex"} flexDirection={{ md: "row", xs: "column" }} gap={4} alignItems={"center"} justifyContent={"space-between"} >
                    <Accordions />
                </Box>
            </Container>
        </div>
    )
}
export default Sfaq
