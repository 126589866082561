import { Box, Typography } from '@mui/material'
import React from 'react'

const DownFooter = () => {
    return (
        <div>
            <Box p={2}>
                <Typography color={'#FFF'} textAlign={'center'} fontWeight={'bold'} >
                    Design and development©All Rights Reserved.<a href="https://revampbrands.com/" target="_blank" rel="noopener noreferrer"
                        style={{ color: 'rgb(255, 176, 0)', textDecoration: 'none' }} >Revamp</a>
                </Typography>
            </Box>
        </div>
    )
}

export default DownFooter
