import { Box, CardMedia, Container, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { products } from './../constants'
import { useParams } from 'react-router-dom'
import Header2 from '../components/Header/Header2'
import SliderService from '../components/SliderService/SliderService'
const Service = () => {
    const { id } = useParams()
    const [product, setProduct] = useState(null);
    useEffect(() => {
        // البحث عن المنتج بناءً على "id"
        const foundProduct = products.find((product) => product.id === Number(id));
        // تحديث الحالة "product"
        setProduct(foundProduct);
    }, [id]);
    return (
        <div>
            <Header2 title={'خدماتنا'} link1={'الصفحة الرئيسية'} link2={'تفاصيل '} />
            <Container>
                <Box key={product?.id} position={'relative'} sx={{ display: 'flex', gap: '2%', alignItems: 'flex-start', flexDirection: { md: 'row', xs: 'column' }, py: 4 }} >
                    <Box sx={{ position: 'relative', width: { md: '49%', xs: '100%' } }} >

                        <SliderService>
                            {
                                product?.imageUrl?.map((imgSrc) =>
                                    <CardMedia
                                        component={'img'}
                                        style={img}
                                        src={imgSrc}
                                    />
                                )
                            }
                        </SliderService>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: { md: '48%', xs: '100%' } }} >
                        <div>
                            <Typography sx={{ color: '#3B70A3', fontWeight: '400', my: 1 }} variant='h4' >
                                {product?.name}
                            </Typography>
                            <Typography sx={{ color: '#B2B4B4', fontWeight: '400', my: 2 }} variant='h5' >
                                {product?.supName}
                            </Typography>
                            <Typography sx={{ color: '#3D3D3D', fontWeight: '500' }} >
                                {product?.desc}
                            </Typography>
                            <br />
                            <br />
                            <Typography sx={{ color: '#3D3D3D', fontWeight: '500' }} >
                                {product?.supDesc}
                            </Typography>
                        </div>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',
    height: '400px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    margin: 'auto'
}
export default Service
