import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { products } from './../../constants'
export default function NavDrawer({ setDrawer, drawer }) {
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            الرئيسية
                        </Link>
                        <Link
                            to="/services"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            خدماتنا
                        </Link>
                        {products.map((product) =>
                            <Link
                                key={product?.id}
                                to={`/service/${product?.id}`}
                                style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }} onClick={() => setDrawer(false)}>

                                {product.supName}
                            </Link>
                        )}
                        <Link
                            to="/about-us"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>
                            من نحن
                        </Link>
                        <Link
                            to="/contact-us"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}>
                            اتصل بنا
                        </Link>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
