import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
const Card = ({ path, name, supName, desc, supDesc }) => {
    return (
        <Container>
            <Box position={'relative'} sx={{ display: 'flex', gap: '2%', alignItems: 'flex-start', flexDirection: { md: 'row', xs: 'column' } }} >

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: { md: '48%', xs: '100%' } }} >
                    <div>
                        <Typography sx={{ color: '#3B70A3', fontWeight: '400', my: 1 }} variant='h4' >
                            {name}
                        </Typography>
                        <Typography sx={{ color: '#000', fontWeight: '400', my: 2 }} variant='h5' >
                            {supName}
                        </Typography>
                        <Typography sx={{ color: '#3D3D3D', fontWeight: '500' }} >
                            {desc}
                        </Typography>
                        <br />
                        <br />
                        <Typography sx={{ color: '#3D3D3D', fontWeight: '500' }} >
                            {supDesc}
                        </Typography>
                    </div>
                </Box>
                <Box sx={{ position: 'relative', width: { md: '49%', xs: '100%' } }} >
                    <CardMedia
                        component={'img'}
                        style={img}
                        src={path}
                    />
                </Box>
            </Box>
        </Container>
    )
}
const img = {
    width: '100%',
    height: '400px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'contain',
    margin: 'auto'
}
export default Card
