import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import path from './../../assets/HowToWork/broom.png'
import path1 from './../../assets/HowToWork/dishwasher.png'
import path2 from './../../assets/HowToWork/plumbing.png'
const HowToWork = () => {
    return (
        <div style={{ padding: '25px 0' }}>
            <Container>
                <Box my={2} >
                    <Typography sx={{ color: '#3B70A3', my: 2, textAlign: 'center' }} variant='h4' >كيف نعمل معك؟</Typography>
                </Box>
                <Grid container spacing={2} sx={{ alignItems: 'flex-start', justifyContent: 'center' }} >
                    <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }} >
                            <Box sx={{
                                background: '#74D7E4',
                                padding: 2,
                                borderRadius: '50%',
                                width: { md: '35%', xs: '50%' },
                                position: 'relative',
                                zIndex: 99,
                                transition: '0.5s',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                },
                                border: '4px solid #FFF'
                            }}>
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={path1}

                                />
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >التقييم</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }} >
                            <Box sx={{
                                background: '#74D7E4',
                                padding: 2,
                                borderRadius: '50%',
                                width: { md: '35%', xs: '50%' },
                                position: 'relative',
                                zIndex: 99,
                                transition: '0.5s',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                },
                                border: '4px solid #FFF'
                            }}>
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={path}

                                />
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >التخطيط والتنفيذ</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1 }} >
                            <Box sx={{
                                background: '#74D7E4',
                                padding: 2,
                                borderRadius: '50%',
                                width: { md: '35%', xs: '50%' },
                                position: 'relative',
                                zIndex: 99,
                                transition: '0.5s',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                },
                                border: '4px solid #FFF'
                            }}>
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={path2}

                                />
                            </Box>
                            <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} >المتابعة والصيانة</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
const img = {

    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    margin: 'auto'
}
export default HowToWork
