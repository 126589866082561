import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, } from '@mui/material'
import Form from '../components/Contact/Form'
import ContactData from '../components/Contact/ContactData'

const Contact = () => {
    return (
        <div>
            <Header2 title={'اتصل بنا'} link1={'الصفحة الرئيسية'} link2={'اتصل بنا'} />
            <Container sx={{ padding: '50px 10px' }} >
                <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 3 }} >
                    <ContactData />
                    <Form />
                </Box>
            </Container>
        </div>
    )
}
export default Contact
