import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box } from '@mui/material'
import Card from '../components/glopal/Card'
import bg from './../assets/DontWhore/66.png'
import HowToWork from '../components/HowToWork/HowToWork'
import Sfaq from '../components/Sfaq/Sfaq'
import OurPartners from '../components/Partners/OurPartners'
// import CardPart from '../components/Partners/CardPart'
// import part1 from './../assets/Ellipse 13.png'
// import part2 from './../assets/testimonial-2-100x100.png'
const About = () => {
    return (
        <div>
            <Header2 title={'من نحن'} link1={'الصفحة الرئيسية'} link2={'من نحن'} />
            <Box sx={{ padding: '25px 0' }}>
                <Card
                    path={bg}
                    name={'شركة الأفضل'}
                    supName={'لخدمات التنظيف المتكامل'}
                    desc={'نحن شركة الأفضل لخدمات التنظيف المتكامل بالرياض و الرائدة في قطاع التنظيف ،نظرا لما نقدمة من خدمات فك وتركيب و عزل الخزانات و إبادة الحشرات و القوارض.'}
                    supDesc={' نفخر بإن لدينا فريق متخصص ومدرب على أعلى مستوى و خبرات طويلة فى مجال التنظيف و الرش و تنسيق الحدائق ، نمتلك أدوات ومعدات ذات جودة عالية لضمان حصولك على نتائج مذهلة ومثالية.'}
                />
                <Sfaq />
                <HowToWork />
                <OurPartners />
            </Box>
        </div>
    )
}

export default About
