import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
// import { footerLinks } from '../../constants'
import { Link } from 'react-router-dom'
import { products } from './../../constants'
import './styleList.css'
const PathPages = () => {

    return (
        <div>
            <Box>
                <Typography sx={{
                    fontWeight: 'bold', color: '#FFF',
                    my: 1
                }} >خدماتنا</Typography>
                <Divider sx={{ backgroundColor: '#FFA53B', borderWidth: '1px', width: '15%' }} />
                <Box
                    sx={{
                        gap: 1,
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: 'row',
                        my: 1
                    }}>
                    <ul style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: 'baseline',
                        padding: 0,
                        textAlign: 'right',
                    }} >
                        {products.map((product) =>
                            <li style={{ listStyle: 'none', display: 'flex', alignItems: 'baseline', gap: '5px', }} className='test'  >
                                <Link
                                    key={product?.id}
                                    to={`/service/${product?.id}`}
                                    style={{
                                        color: "#fff",
                                        textDecoration: "none",
                                        width: '158px',
                                        fontSize: '14px'

                                    }}>
                                    {product.supName}
                                </Link>
                            </li>
                        )}
                    </ul>
                    {/* <Link
                        to="/Channel-Letters"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        مكافحة حشرات
                    </Link>
                    <Link
                        to="/Interfaces"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنضيف منازل بالرياض
                    </Link>
                   
                    <Link
                        to="/Hoarding-Signs"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنظيف مجالس
                    </Link>
                  
                    <Link
                        to="/ACP-Wood"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنظيف شقق
                    </Link>
                   
                    <Link
                        to="/Various-Project"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنظيف موكت
                    </Link>
                    
                    <Link
                        to="/Entrances-Gates"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنظيف سجاد
                    </Link>
                    
                    <Link
                        to="/ACP-Colomns"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        رش مبيد
                    </Link>
                   
                    <Link
                        to="/Kiosks"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنضيف قصور
                    </Link>
                   
                    <Link
                        to="/Car-Vinyl"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنظيف فنادق
                    </Link>
                   
                    <Link
                        to="/Stand"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        تنظيف مسابح
                    </Link>
                   
                    <Link
                        to="/decor"
                        style={{
                            color: "#FFF",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        عزل خزانات
                    </Link> */}
                </Box>
            </Box>
        </div>
    )
}

export default PathPages
