import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Grid } from '@mui/material'
import Card from '../components/ourService/Card'
import { products } from './../constants'
const Services = () => {
    return (
        <div>
            <Header2 title={'خدماتنا'} link1={'الصفحة الرئيسية'} link2={'خدماتنا'} />
            <Container sx={{ padding: { md: '50px 0', xs: '25px' } }} >
                <Box>
                    <Grid container spacing={4} >
                        {products.map((product) =>
                            <Grid item md={6} xs={12} key={product?.id} >
                                <Card path={product.imageUrl[0]}
                                    desc={product.desc}
                                    name={product.supName}
                                    route={`/service/${product?.id}`} />
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default Services
