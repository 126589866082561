import React from 'react'
import Header from '../components/Header/Header'
import AboutUs from '../components/AboutUs/AboutUs'
import OurServices from '../components/ourService/OurServices'
import OurPartners from '../components/Partners/OurPartners'
import DontWhore from '../components/dontWhore/DontWhore'
const Home = () => {
    return (
        <div>
            <Header />
            <AboutUs />
            <DontWhore />
            <OurServices />
            <OurPartners />

        </div>
    )
}

export default Home
