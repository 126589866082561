import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import aboutHome from './../../assets/AboutUs/cleaning-service.png'

const AboutUs = () => {
    return (
        <div style={{ padding: '40px 0 0 0' }} >
            <Container>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'space-between',
                    flexDirection: { md: 'row', xs: 'column' }
                }} >
                    <Box sx={{
                        width: { md: '50%', xs: '100%' },
                        padding: { md: 2, xs: 1 },
                        height: { md: '30vh', xs: '35vh' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center'
                    }} >
                        <Typography variant='h5' sx={{ color: '#3B70A3' }} >
                            شركة الأفضل خبراء التنظيف المتكامل بالرياض
                        </Typography>
                        <Typography sx={{ color: '#969696' }} >
                            الأفضل شركة تنظيف بالرياض
                        </Typography>
                        <Typography>
                            الأفضل رواد التنظيف المنزلى المتكامل بالرياض و ضواحيها تمتلك
                            سنوات طويلة من الخبرات و أفضل فريق عمل محترف في مجال
                            التنظيف المتكامل للمنازل و الفيلل و القصور و الشركات
                        </Typography>
                    </Box>
                    <Box >
                        <CardMedia
                            component={'img'}
                            style={img}
                            src={aboutHome}
                            sx={{ height: '450px', objectFit: { md: 'fill', xs: 'contain' }, }}
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',

    borderRadius: '12px',
    backgroundSize: 'cover',
    // boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',

    margin: 'auto',

}
export default AboutUs
