import React from 'react'
import Carousel from 'react-material-ui-carousel'

const SliderService = ({ children }) => {
    return (

        <>
            <Carousel
                sx={{
                    marginTop: "25px",
                    width:  "100%",
                    margin: "auto"
                }}>
                {children}
            </Carousel>
        </>
    )
}

export default SliderService