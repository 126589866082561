import { Box, Typography } from '@mui/material'
import React from 'react'
import CardContact from './CardContact'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import bg from './../../assets/contact/business-team-joining-hands-together-city-background.png'
const ContactData = () => {
    return (

        <Box sx={{ background: `url(${bg})`, position: 'relative', p: 4, borderRadius: '18px', width: { md: '40%', xs: '100%' } }}>
            <div className="overlayF" style={{ borderRadius: '18px' }} ></div>
            <Box sx={{ position: 'relative', zIndex: 9 }} >
                <Typography sx={{ color: '#fff', my: 2, textAlign: 'center' }} variant='h4' >تواصل معنا</Typography>
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between', mt: 4,
                    flexDirection: { md: 'column', xs: 'column' }, gap: 8
                }} >
                    <CardContact name={'العنوان'} desc={'السعودية - الرياض'} >
                        <LocationOnIcon sx={{ color: '#74D7E4', fontSize: 60, position: 'absolute', top: '-25%' }} />
                    </CardContact>
                    <a href="tel:+966539431456" style={{ textDecoration: 'none', color: '#FFF' }} >
                        <CardContact name={'رقم التليفون'} desc={'+966539431456'} >
                            <PhoneIphoneIcon sx={{ color: '#74D7E4', fontSize: 60, position: 'absolute', top: '-25%' }} />
                        </CardContact>
                    </a>
                </Box>
            </Box>
        </Box>
    )
}

export default ContactData