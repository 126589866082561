import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import costumService from './../../assets/DontWhore/66.png'
import tm from './../../assets/DontWhore/logo1.png'
import ta from './../../assets/DontWhore/logo2.png'
import './DontWhore.css'
const DontWhore = () => {
    return (
        <div style={{ padding: '40px 0' }}  >
            <Box sx={{ py: 2 }} >
                <div className="bg">
                    <div className="overlayD"></div>
                    <Container>
                        <Box sx={{
                            display: 'flex', flexDirection: { md: 'row', xs: 'column' },
                            alignItems: 'center', justifyContent: 'space-between', width: '100%', position: 'relative', zIndex: 9
                        }} >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, color: '#FFF' }} >
                                <Typography variant='h5' >لا تشيل هم النظافه والصيانه مع الأفضل</Typography>
                                <Typography className='cercleD' >منازل  - شقق - مدارس  - مباني - شاليهات - فنادق </Typography>
                                <Typography className='cercleD' >الأثاث  - الأرضيات- الخزانات  - السجاد - الستائر - المداخل - الدرج </Typography>
                                <Typography className='cercleD' >أحدث الأجهزه وأفضل مواد النضافه والتعقيم لمكافخة الحشرات</Typography>
                                {/* <Typography variant='h5' >يوجد لدينا خدمة الدفع أقساط</Typography> */}
                                <Box sx={{ display: 'flex', mt: '5%', justifyContent: 'flex-start', gap: '24px' }} >
                                    <CardMedia
                                        component={'img'}
                                        sx={{ width: '125px' }}
                                        src={ta}
                                    />
                                    <CardMedia
                                        component={'img'}
                                        sx={{ width: '125px' }}
                                        src={tm}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <CardMedia
                                    component={'img'}
                                    src={costumService}
                                    sx={{
                                        width: '100%',
                                        height: '350px',
                                        objectFit: { md: 'fill', xs: 'contain' },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Container>
                </div>
            </Box>
        </div>
    )
}
export default DontWhore
