import React from 'react'
import { Box, CardMedia, Typography } from '@mui/material';
import Carousel from "react-material-ui-carousel";
import header2 from './../../assets/header/slider-h1-1.png'
import header3 from './../../assets/header/slider-h1-2.png'
import header4 from './../../assets/header/hands-cleaning-window.jpg'
const Header = () => {
    return (
        <>
            <Box position={'relative'} >
                <Carousel
                    navButtonsAlwaysVisible={true}
                    sx={{
                        width: "100%",
                        height: { md: "600px", xs: "300px" },
                        margin: "auto"
                    }}
                >
                    <CardMedia
                        sx={{ height: { md: "600px", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header2}
                        style={{

                            width: "100%",
                            objectFit: "cover",

                        }}
                    />
                    <CardMedia
                        sx={{ height: { md: "600px", xs: "300px" } }}

                        component="img"
                        alt="green iguana"
                        src={header4}
                        style={{
                            width: "100%",
                            objectFit: "cover",

                        }}
                    />

                    <CardMedia
                        sx={{ height: { md: "600px", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header3}
                        style={{

                            width: "100%",
                            objectFit: "cover",

                            backgroundPosition: 'right',
                        }}
                    />
                </Carousel>
                <Box sx={{
                    position: 'absolute', top: '50%',
                    left: '10%',
                    padding: 2,
                    transform: 'translateY(-50%)', zIndex: 999, display: { md: 'inherit', xs: 'none' }
                }} >
                    <Typography variant='h4' sx={{ textAlign: 'center', color: '#3B70A3', fontWeight: '500' }}>
                        شركة الأفضل <br />لخدمات التنظيف المتكامل
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default Header
