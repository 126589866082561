import { Box, CardMedia } from '@mui/material'
import React from 'react'
import InstagramIcon from './../../assets/social/Path 369.png';
import FacebookIcon from './../../assets/social/Path 353.png';
import TwitterIcon from './../../assets/social/Path 419.png';
const SothialContact = () => {
    return (
        <div>
            <Box style={social}>
                <Box style={BoxSocial} >
                    <a href="https://www.instagram.com/afdalinst?igsh=ODA1NTc5OTg5Nw=="
                        target="_blank" rel="noopener noreferrer">
                        <CardMedia
                            component={'img'}
                            src={InstagramIcon}
                            sx={{ width: '25px', height: '25px', objectFit: 'contain' }}
                        />
                    </a>
                    {/* <InstagramIcon sx={{ color: '#FFF', fontSize: '1.5rem' }} /> */}
                </Box>
                <Box style={BoxSocial} >
                    <a href="https://www.facebook.com/afdalco/"
                        target="_blank" rel="noopener noreferrer">
                        <CardMedia
                            component={'img'}
                            src={FacebookIcon}
                            sx={{ width: '25px', height: '25px', objectFit: 'contain' }}
                        />
                        {/* <FacebookIcon sx={{ color: '#FFF', fontSize: '1.5rem' }} /> */}
                    </a>
                </Box>
                <Box style={BoxSocial} >
                    <a href="https://twitter.com/elafdalx"
                        target="_blank" rel="noopener noreferrer">

                        <CardMedia
                            component={'img'}
                            src={TwitterIcon}
                            sx={{ width: '25px', height: '25px', objectFit: 'contain' }}
                        />
                    </a>
                    {/* <TwitterIcon sx={{ color: '#FFF', fontSize: '1.5rem' }} /> */}
                </Box>
            </Box>
        </div>
    )
}

const BoxSocial = {

    display: 'flex',
    alignItems: 'center',
    margin: '16px 0'

}
const social = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
}
export default SothialContact
