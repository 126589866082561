import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
// import CardLogo from './CardLogo'
import PathPages from './PathPages'
import ContactData from './ContactData'
import DownFooter from './DownFooter'
import Information from './Information'
import { Link } from 'react-router-dom'
import bg from './../../assets/footer/19362690.png'
const Footer = () => {
    return (
        <div>
            {/* <Container> */}
            <Box sx={{ height: '115px', background: '#3B70A3', display: 'flex', alignItems: 'center', }} >
                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '5%' }} >
                        <Typography sx={{ fontWeight: '400', color: '#FFF', fontSize: '28px' }}  >تواصل معنا لطلب المزيد</Typography>
                        <Link
                            to="/contact-us"
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                        >
                            <Button variant="contained"
                                type="button"
                                sx={{
                                    backgroundColor: '#FFF', ':hover': {
                                        backgroundColor: '#FFF',
                                        color: '#3B70A3',
                                    },
                                    color: "#3B70A3",
                                    fontWeight: 'bold'
                                }}>تواصل معنا</Button>
                        </Link>
                    </Box>
                </Container>
            </Box>
            {/* </Container> */}
            <Box sx={{ background: `url(${bg})`, position: 'relative', backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover' }} >
                <div className="overlayF"></div>
                <Container>
                    <Grid container spacing={2} sx={{ alignItems: 'flex-start', justifyContent: 'center', pt: 2, position: 'relative' }} >
                        <Grid item md={4} xs={12}>
                            <Information />
                        </Grid>
                        <Grid item md={1} xs={12}></Grid>
                        <Grid item md={4} xs={12}>
                            <PathPages />
                        </Grid>
                        <Grid item md={1} xs={12}>
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <ContactData />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <DownFooter />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}
export default Footer
